import React from 'react';
import _ from 'lodash';
import { MobileMenu, Menu, MenuTypefaces, MenuScripts, Footer, BackgroundColorChanger, Emblem } from '../components';
import { changeLoginInfo, changeBwMode, setCart, windowResize, changeMobileMenuColors, setTypefacesPopup } from '../actions';
import { connect } from 'react-redux';
import { Container, MenuRightSingleGutter,MenuGutter404, Gutter, BackButton, RightAboutColumn, BorderArea } from '../stylesheets/components';
import styled from 'styled-components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const CenterArea = styled.div`
  position: absolute;
  margin-left: calc(100vw - 80vw);
  color: white;

  ${media.mobileLarge`
    margin-left: 10vw
  `}
`;
const H1 = styled.h1`

font-size: 3em;
line-height: initial;
`;

const H3 = styled.h1`
color: white;
font-size: 1.5em;
line-height: initial;
`;

const EmblemArea = styled.div`
  position: absolute;
  transform: translate(0, -50%);
  z-index: 5;
  left: 24px;
  top: 50vh;
  width: 114px;
  height: 122px;

  ${media.mobileLarge`
    left: 15px;
    top: 75px;
    width: 48px;
    height: 50px;
    transform: none;
  `}
`;

class NotFound extends React.Component {

  render() {
    let { bwMode, products, languages } = this.props;

    return (
      <Fragment>

        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#8040F6" currentPage="NOTFOUND" />
        <EmblemArea>
          <Emblem fill={"white"} />
        </EmblemArea>
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="#5900ff" />
        <BackgroundColorChanger color="#FE5B02" bwMode="black" />

        <MenuRightSingleGutter h={200}> </MenuRightSingleGutter>

        <Container>
          <MenuGutter404></MenuGutter404>
          <CenterArea>
          <H1>Oooops, We can't find the page <br /> You're looking for </H1>
          <H3>
          Error 404. The page you're looking for doesn't seem to be working.<br /> If it's something on our end, you can be sure we're doing <br />
our best to get it fixed. Or this page doesn't exist yet.
          </H3>

          <BackButton href="/">Back to Homepage</BackButton>
          </CenterArea>


        </Container>
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#8040F6" currentPage="Typefaces" />

      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}

export default connect(mapStateToProps)(NotFound);
