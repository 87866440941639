import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeBwMode } from '../actions';

const Fragment = React.Fragment;

class BackgroundColorChanger extends Component {
  componentDidMount(){
    // console.log("backgroundColorchanger", this.props.bwMode);
    document.body.style.background = this.props.color;
    this.props.dispatch(changeBwMode(this.props.bwMode));
  }

  render() {
    return (
      <Fragment>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {

  }
}


export default connect(mapStateToProps)(BackgroundColorChanger);
