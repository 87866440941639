import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';
import FontFaceObserver from 'fontfaceobserver';
import { constrain } from '../utils';
import { connect } from 'react-redux';

const Area = styled.div`
  ${props => !props.last ? "border-bottom: 1px solid white;" : "" }
  padding: 80px 0;
  text-align: center;

  ${media.mobileSmall`
    padding: 60px 0;
  `}
  :first-child {
    padding: 107px 0 80px 0;

    ${media.mobileSmall`
      padding: 107px 0 60px 0;
    `}
  }
  :last-child {
    border-bottom: none;
  }

`;

const Title = styled.a`
  color:white;
  display:block;
  font-size: 128px;
  line-height: 1.1em;
  overflow:hidden;

  ${media.mobileLarge`
    overflow:hidden;
    text-align: left;
    margin-left: 15px;
    padding-left: 5px;
    white-space: nowrap;
  `}

  ${media.mobileSmall`
    font-size:36vw;
    padding: 0;
    text-align: center;
    width: calc(100% - 20px);
    margin: 0 auto;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px 0 0 16px;

  div, span {
    color: white;
  }

  ${media.mobileSmall`
    display:block;
    justify-content: flex-start;
  `}


`;
const ProductInfoCol1 = styled.span`
  ${media.mobileSmall`
    display: block;
    width: calc(50%);
    float:left;
  `}
`
const ProductInfoCol2 = styled.span`
  ${media.mobileSmall`
    display: block;
    margin-right: 0;
  `}
`

const ProductName = styled.span`
  font-size: 16px;
  margin-right: 2em;

  ${media.mobileSmall`
    display: block;
    margin-right: 0;
    text-align: left;
  `}

`
const ProductVariantLength = styled.span`
  font-size: 16px;
  margin-right: 2em;

  ${media.mobileSmall`
    text-align: left;
    display: block;
    margin-right: 0;
  `}

`
const ProductLanguages = styled.span`
  font-size: 16px;

  ${media.mobileSmall`
    text-align: left;
    display: block;
    margin-right: 0;
  `}
`

const Language = styled.span `
  margin-left: 0;
  text-align: left;
  font-size:0.889em;
`;
class TypefacePreview extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount(){
    document.body.style.background = "black";

    // const font = new FontFaceObserver(this.props.product_name);
    // console.log(this.props.product_name, "start loading");
    // font.load(null, 10000).then(() => {
    //   console.log(this.props.product_name, "start loading complate");
    //   this.setState({isLoading: false})
    // }, err => {
    //   console.error('Failed to load fonts!', err)
    // });

    this.fontCheck();
  }

  fontCheck(){
    var fontInterval = setInterval(() => {
      let fontfamily =  (this.props.default_variant.product_name + '-' + this.props.default_variant.variant_name).replace(/ /g,'')

      if (document.fonts.check(`12px '${fontfamily}'`)){
        clearInterval(fontInterval);
        this.setState({isLoading: false})
      }
    }, 100);
  }

  render() {
    let { isLoading } = this.state;
    let { last, windowWidth } = this.props;

    let fontfamily =  (this.props.default_variant.product_name + '-' + this.props.default_variant.variant_name).replace(/ /g,'')

    // let containerHeight = constrain(windowHeight * 0.9, 170 * 2.5, 266 * 2.5);
    // let fontSize = constrain(containerHeight * 0.3, 128, 200);
    // debugger;
    let style =
      {
        fontFamily: `${fontfamily}, AdobeBlank`,
        fontWeight: this.props.default_variant.weight,
        fontStyle: this.props.default_variant.style,
        opacity: isLoading ? 0 : 1,
        lineHeight: `${this.props.line_spacing}em`
      };

    return (
      <Area last={last}>
        <Title style={style} href={`/products/${this.props.permalink}`} dangerouslySetInnerHTML={{__html: windowWidth <= 414 ? this.props.mobile_example_text : this.props.example_text }} />
        <Wrapper className="no-webfont">
          <ProductInfoCol1>
            <ProductName>
              { this.props.product_name }
            </ProductName>

            <ProductVariantLength>
              { this.props.variant_count } buying option{ this.props.variant_count === 1 ? "" : "s"}
            </ProductVariantLength>
          </ProductInfoCol1>
          <ProductInfoCol2>
            <ProductLanguages>
              {
                _.map (this.props.languages, l => l.language_name).join(", ")
              }
            </ProductLanguages>

          </ProductInfoCol2>
        </Wrapper>
        <link rel="stylesheet" href={`/api/product_variants/${this.props.default_variant.id}.css`} async/>
      </Area>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default connect(mapStateToProps)(TypefacePreview);
