import styled from 'styled-components';
import mixins from './mixins';
import media from './media';
import { COLUMN_WIDTH } from '../constants/defaults';

const FormBorderWrapper = styled.div`
  border: 1px solid ${props => props.bwMode === "white" ? "black" : "white" };
`;


const PaddingDiv = styled.div`
  padding: 24px 32px;
  max-width: ${COLUMN_WIDTH};


  ${media.mobileLarge`
    padding: 10px 15px;
    max-width: inherit;
    width: calc(100% - 30px);
  `}
`;


const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 5px;
  }

  ${media.mobileSmall`
    display: block;
    justify-content: center;
  `}

  div {

  ${media.padLandscape`
    margin-bottom: 10px;
  `}
  }
`;

const PrimaryButton = styled.button`
  border:1px solid ${props => props.bwMode === "white" ? "black" : "white" };
  background: ${props => props.bwMode === "white" ? "black" : "white" };
  color: ${props => props.bwMode === "white" ? "white" : "black" };
  border-radius: 50px;
  padding: 6px 24px;
  font-size: 1.5em;
  cursor: pointer;
  margin-bottom: 10px;

  &:disabled {
    background: transparent;
    cursor: auto;
    color: #AAA;
    border: 1px solid #BBB;
  }
`;

const ButtonAFill = styled.a`
  display: inline-block;
  border: 1px solid ${props => props.bwMode === "white" ? "black" : "white"};
  background: ${props => props.bwMode === "white" ? "black" : "white" };
  color: ${props => props.bwMode === "white" ? "white" : "black"};
  border-radius: 50px;
  padding: 10px 24px;
  font-size: 1.5em;
  line-height: 1;

  ${media.mobileLarge`
    /* font-size:0.9em; */
    padding: 5px 24px;
  `}
`;

const BackButton = styled.a`
  margin-top: 40px;
  display: inline-block;
  border: 1px solid ${props => props.bwMode === "white" ? "black" : "white"};
  background: transparent;
  color: white;
  border-radius: 15px;
  padding: 10px 24px;
  font-size: 1.5em;
  line-height: 1;

  ${media.mobileLarge`
    /* font-size:0.9em; */
    padding: 5px 24px;
  `}
`;

const ButtonA = styled.a`
  display: inline-block;
  border: 1px solid ${props => props.bwMode === "white" ? "black" : "white"};
  color: ${props => props.bwMode === "white" ? "black" : "white"};
  border-radius: 50px;
  padding: 10px 24px;
  font-size: 1.5em;

  /* ${media.mobileLarge`
    font-size:0.9em;
  `} */
`;

const ButtonAWhite = styled.a`
  display: inline-block;
  border: 1px solid ${props => props.bwMode === "white" ? "white" : "black"};
  color: ${props => props.bwMode === "white" ? "white" : "black"};
  background: ${props => props.bwMode === "white" ? "black" : "white" };
  border-radius: 50px;
  padding: 10px 24px;
  font-size: 1.4em;
  line-height:1.3;

  /* ${media.mobileLarge`
    font-size:0.9em;
  `} */
`;

const FormField = styled.div`
  border-bottom: 1px solid ${props => props.bwMode === "white" ? "black" : "white" };
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobileSmall`
    display: block
  `}

  label {
    color: ${props => props.bwMode === "white" ? "black" : "white" };
    width: 50%;
    ${media.mobileSmall`
      width: 100%;
      padding-top: 9px;
      padding-bottom: 0;
      padding-left: 2px;
    `}
  }

  div.right {
    width: 50%;
    font-size: 1.0em;
    color: ${props => props.bwMode === "white" ? "black" : "white" };
    background:transparent;

    ${media.mobileSmall`
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

    `}

    input, select {

      width: 100%;

      ${media.mobileSmall`
        width: 100%;
        padding: 0;
      `}
    }

    select {
      background: none;
      color: ${props => props.bwMode === "white" ? "black" : "white" };
      border:none;
      font-size:1.0em;
      font-family: "UT Sans", sans-serif;
      padding:0;
      margin:0;
      margin-left:-3px;
    }
  }


  span.error {
    color: #FF0000;
    font-size: 0.8em;

  }

  input {
    border: none;
    width: 50%;
    font-size: 1.0em;
    color: ${props => props.bwMode === "white" ? "black" : "white" };
    background:transparent;

    ${media.mobileSmall`
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

    `}
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.5; /* Firefox */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.5; /* Firefox */
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const MenuLink = styled.a`
  display: block;
  font-size:1.0em;
  margin-bottom:-1px;
  transition: 0.2s color;
  color: ${props =>
    props.typefacesPopup ?
      "white" :
      (
        props.active ?
          props.highlightColor :
          (
            props.firstmenuColor ?
              props.firstmenuColor :
              (
                props.bwMode === "black" ? "white" : "black"
              )
          )
      )
  };
`;

const Gutter = styled.div`
  height: ${props => props.h}px;
`;

const CenterWrapper = styled.div`
  width: 1000px;
  margin: 0 auto;

  ${media.mobileLarge`
    width: auto;
    padding: 10px 15px;
  `}
`;

const DesktopOnly = styled.div`
  display: block;

  ${media.mobileLarge`
    display: none;
  `}
`;

const MobileOnly = styled.div`
  display: none;

  ${media.mobileLarge`
    display: block;
  `}
`;


const Title = styled.h2`
  font-size:4.0em;
  line-height:1.05;
  color: ${props => props.bwMode === "black" ? "white" : "black" };
  word-break: break-all;

  ${media.mobileLarge`
    font-size: 2.66666666em;
  `}
`;

const MenuGutter = styled.div`
  height: 350px;

  ${media.mobileLarge`
    height: 110px;
  `}
`;

const MenuGutter404 = styled.div`
 display: none;
${media.mobileLarge`
  height: 150px;
  display: block;
`}
`;

const MenuRightSingleGutter = styled.div`

  height: ${props => props.h}px;

  ${media.mobileLarge`
    display: none;
  `}

`;

const MenuRightGutter = styled.div`
  height: 367px;

  ${media.mobileLarge`
    display: none;
  `}
`;

const MenuArticleGutter = styled.div`
  height: 110px;

  ${media.mobileLarge`
    display: none;
  `}
`;

const Container = styled.div`

  p, h3 {
    color: ${props => props.bwMode === "black" ? "white" : "black" };
  }

`;


const HalfContainer = styled.div`
  display:flex;
  justify-content: space-between;

  ${media.mobileLarge`
    display: block;
  `}

`;
const FinalButtonArea = styled.div`
  display: flex;

  button {
    margin-right: 15px;
  }
  ${media.mobileLarge`
    justify-content: center;

  `}
`;

const LeftColumn = styled.div`
  width: calc(50% - 1px);
  /* border-right: 1px solid ${props =>
    props.foregroundColor ?
    props.foregroundColor :
    (props.bwMode === "white" ? "black" : "white")
  }; */


  ${media.mobileLarge`
    width: 100%;
    /* border-right: none; */
    /* padding-bottom: 10px; */
    /* border-bottom: 1px solid ${props => props.bwMode === "white" ? "black" : "white"}; */
  `}

`;

const RightAboutColumn = styled.div`
  width: calc(50% - 1px);
  display:flex;


  ${media.mobileLarge`
    width: 100%;

    display: block;
  `}
`;
const TestString = styled.div`
  font-size: 18px;
  position:fixed;
  left:-9999px;
  top:-9999px;
  visibility:hidden;
  color:black;
  background-color: white;
  border: 1px solid black;
  box-shadow: none;
  padding: 5px 47px 5px 15px;
  border-radius: 30px;
`;

const TestStringEmpty = styled.div`
  font-size: 18px;
  position:fixed;
  left:-9999px;
  top:-9999px;
  visibility:hidden;
  color:black;
  background-color: white;
  border: 1px solid black;
  box-shadow: none;
  padding: 5px 20px 5px 20px;
`;


const RightColumn = styled.div`
  width: calc(50% - 1px);


  ${media.mobileLarge`
    width: 100%;

    display: block;
  `}
`;
const BorderArea = styled.div`

  background: linear-gradient(${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
  }, ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
  }) no-repeat center/1px 100%;


  ${media.mobileLarge`
    background: none;
  `}
`;


const TypefaceArea = styled.div`
  /* position: absolute; */
  background-color: black;
  /* top: 0; */
  /* left: ${props => props.scriptsPopup ? "-240px" : 0}; */
  /* z-index:2; */
  /* padding-top:20px; */
  /* min-height:100vh; */
/*
  ${media.mobileLarge`
    width: 100%;
  `} */
`;

const DesktopHr = styled.hr`
  display: block;
  margin:0;

  border: none;
  border-bottom: 1px solid ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
  };

  ${media.mobileLarge`
    display: none;
  `}
`;

const OneLineDesktopGutter = styled.div`
  height: 498px;

  @media (max-width: 2559px) {
    height: 489.5px;
  }
  @media (max-width: 2048px) {
    height: 473px;
  }

  ${media.mobileLarge`

    display: none;

  `}
`;

const TwoLineDesktopGutter = styled.div`
  height: 498px;

  @media (max-width: 2559px) {
    height: 489.5px;
  }
  @media (max-width: 2048px) {
    height: 473px;
  }

  @media (max-width: 1193px) {
    height: 548px;
  }

  ${media.mobileLarge`

    display: none;

  `}

`;

const DesktopGutter = styled.div`
  height: ${props => props.h}px;

  ${media.mobileLarge`

    display: none;

  `}

`;


const FlashNotice = styled.div`
  border:1px solid ${props => props.bwMode === "white" ? "black" : "white"};
  padding: 15px;
  margin-bottom: 10px;
  color:${props => props.bwMode === "white" ? "white" : "black"};
  background-color:${props => props.bwMode === "white" ? "black" : "white"};
`;


const CartLink = styled.a`
  display: flex;
  transition: background-color 0.2s;
  background-color: ${props => props.color ? props.color : (props.bwMode === "white" ? "black" : "white") };
  border-radius: 100px;
  padding: 4px 10px;

  display: flex;
  align-items: center;

  svg.icon {
    margin-right: 10px;
  }

  span.label {
    color: ${props => props.bwMode === "white" ? "white" : "black" };
    font-size: 22px;
    margin-left: 4px;
    margin-right: 6px;
  }
`;

const ButtonAPrice = styled(ButtonA)`
  line-height: 1.3;
  margin-right: 15px;

  &:hover {
    opacity: 1;
  }
`;


export {
  FlashNotice,
  BorderArea,
  ButtonAPrice,
  OneLineDesktopGutter,
  Title,
  DesktopHr,
  MenuLink,
  Container,
  Gutter,
  CenterWrapper,
  HalfContainer,
  LeftColumn,
  RightColumn,
  RightAboutColumn,
  FormField,
  FormBorderWrapper,
  PrimaryButton,
  ButtonA,
  PaddingDiv,
  ButtonArea,
  ButtonAWhite,
  ButtonAFill,
  BackButton,
  TypefaceArea,
  MenuGutter,
  MenuGutter404,
  MenuRightGutter,
  MenuArticleGutter,
  MenuRightSingleGutter,
  FinalButtonArea,
  DesktopGutter,
  TwoLineDesktopGutter,
  TestString,
  TestStringEmpty,
  CartLink,
  DesktopOnly,
  MobileOnly
};
