import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { UserLoginComponent } from './';
import { changeMobileMenuVisible, changeMobileMenuColors } from '../actions';
import { MenuLink } from '../stylesheets/components';
import media from '../stylesheets/media';

const Header = styled.div`
  position: absolute;
  left: 24px;
  top: 24px;
  z-index:5;

  h1 {
    transition: 0.2s color;
    color: ${props => props.foregroundColor ? props.foregroundColor : (props.bwMode === "black" ? "white" : "black") };
  }

  ${media.mobileLarge `
    left: 15px;
    top: 15px;
  `}

`;

const Title = styled.h1`
  font-size: 1.8em;
  line-height: 1.0;

  ${media.mobileLarge`
    font-size:1.4em;
  `}
`;

const MenuLinkArea = styled.div`
  margin-top: 30px;

  ${media.mobileLarge`
    display: none;
  `}
`;

const MenuMobileArea = styled.div`
  display: none;

  ${media.mobileLarge`
    display: block;
    position: absolute;
    right: 15px;
    top: 22px;
    z-index:5;
  `}
`;

const PageIndicatorMobile = styled.div`
  display: none;

  ${media.mobileLarge`
    display: block;
    position: absolute;
    left: 14px;
    top: 64px;
    z-index:5;
    font-size:1.4em;
  `}
`;

class Menu extends Component {
  constructor(props){
    super(props);

    this.state = {
      fontLoaded: false
    };
  }
  componentDidMount(){
    this.checkFontLoad();
  }

  checkFontLoad(){
    this.fontInterval = setInterval(() => {
      if (document.fonts.check("12px 'UT Sans'")){
        clearInterval(this.fontInterval);

        this.setState({
          fontLoaded: true
        })
      }
    }, 100);
  }

  componentWillUnmount(){
    clearInterval(this.fontInterval);
  }

  handleHamburgerClick(e) {
    e.preventDefault();
    this.props.dispatch(changeMobileMenuVisible(true));
  }

  render() {
    let { typefacesPopup, bwMode, highlightColor, currentPage, firstpage, foregroundColor, scriptsPopup, cart } = this.props;
    let { fontLoaded } = this.state;

    let isCartOn = false;
    if (cart) {
      isCartOn = cart.cart_items.length > 0;
    }
    return (
      <Fragment>
        <Header style={{ opacity: fontLoaded ? 1 : 0 }} bwMode={bwMode} foregroundColor={foregroundColor}>
          <a href="/">
            <Title>
              Universal<br />
              Thirst
            </Title>
          </a>

          <MenuLinkArea>
            <MenuLink bwMode={bwMode} typefacesPopup={typefacesPopup} firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "Products"} href="/products">Typefaces</MenuLink>
            <MenuLink bwMode={bwMode} typefacesPopup={typefacesPopup} firstmenuColor={foregroundColor} highlightColor={highlightColor} active={false} target="_blank" href="https://gazette.universalthirst.com">Gazette</MenuLink>
            <MenuLink bwMode={bwMode} typefacesPopup={typefacesPopup} firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "About"} href="/about">About</MenuLink>
            <MenuLink bwMode={bwMode} typefacesPopup={typefacesPopup} firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "Custom Work"} href="/custom-work">Custom Work</MenuLink>
            <MenuLink bwMode={bwMode} typefacesPopup={typefacesPopup} firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "FAQ"} href="/faq">FAQ</MenuLink>
            <UserLoginComponent typefacesPopup={typefacesPopup} foregroundColor={foregroundColor} isLogoutEnabled={this.props.isLogoutEnabled} />
          </MenuLinkArea>
        </Header>

        <MenuMobileArea isCartOn={isCartOn}>
          <a href="#" onClick={this.handleHamburgerClick.bind(this)}>
            <svg width="16" height="14" viewBox="0 0 16 14">
              <rect width="16" height="2" fill={foregroundColor} />
              <rect y="6" width="16" height="2" fill={foregroundColor} />
              <rect y="12" width="16" height="2" fill={foregroundColor} />
            </svg>
          </a>
        </MenuMobileArea>

        {
          currentPage !== "Welcome" && currentPage !== "NOTFOUND" ?
          <PageIndicatorMobile bwMode={bwMode} style={{color: highlightColor}}>{ currentPage }</PageIndicatorMobile>
          : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup,
    scriptsPopup: state.scriptsPopup,
    cart: state.cart
  };
}

export default connect(mapStateToProps)(Menu);
